import { Button } from 'nzk-react-components'
import React from 'react'
import styled, { css } from 'styled-components'
import { useOnboardingPageState } from '../index.state'

type StepStatus = 'CURRENT' | 'DONE' | 'NOT_DONE'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
`
const Content = styled.div`
  position: relative;
  display: inline-flex;
  > * {
    padding-right: 20px;
    z-index: 1;
  }
  > :last-child {
    padding-right: 0;
  }
`

const Step = styled.div`
  position: relative;
  z-index: 1;
  :before {
    position: absolute;
    top: calc(50% - 7px);
    left: 0;
    height: 7px;
    width: 100%;
    content: '';
    background-color: #fff;
    z-index: 0;
  }
  ${({ status }: { status: StepStatus }) => {
    if (status === 'CURRENT')
      return css`
        :before {
          background-color: #701ebd;
        }
      `
    if (status === 'NOT_DONE')
      return css`
        :before {
          background-color: #b0b0b0;
        }
      `
    return css`
      :before {
        background-color: #8cc63f;
      }
    `
  }}
`

export default () => {
  const { stepsHook } = useOnboardingPageState()
  const { STEPS, goToStepIndex, stepIndex } = stepsHook

  const getStepStatus = (i): StepStatus => {
    if (stepIndex > i) {
      return 'DONE'
    }
    if (stepIndex === i) {
      return 'CURRENT'
    }
    return 'NOT_DONE'
  }

  return (
    <Wrapper>
      <Content>
        {STEPS.map((s, i) => (
          <Step key={s} status={getStepStatus(i)}>
            <Button
              round
              size="small"
              theme={getStepStatus(i) === 'CURRENT' ? undefined : 'confirm'}
              backgroundColor={
                getStepStatus(i) === 'CURRENT' ? '#701ebd' : undefined
              }
              disabled={getStepStatus(i) === 'NOT_DONE'}
              onClick={() => goToStepIndex(i)}
              key={s}
            >
              {i + 1}
            </Button>
          </Step>
        ))}
      </Content>
    </Wrapper>
  )
}
