import { Button, Help } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'
import { ModalState } from '../../../../state/ModalState'
import HelpModal from './HelpModal'
import Input from './Input'

const Wrapper = styled.div`
  overflow: hidden;
  padding: 0px 0;
  > :nth-child(2n + 1) {
    background-color: rgba(255, 255, 255, 0.05);
  }
`

const Field = styled.div`
  font-size: 14px;
  padding: 10px 15px;
  > .field--title {
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .selected {
    font-size: 18px;
    color: #fff;
    border-radius: 6px;
    padding: 8px 16px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 2px;
    display: inline-block;
    width: 100%;
    outline: none;
    background-color: #45106b;
    border: 1.5px solid #45106b;
    font-family: 'Libre Baskerville';
  }
`

const HelpButton = styled.div`
  display: inline-flex;
  place-self: right;
`

interface IChildCardProps {
  child: {
    username: string
    nickname: string
    birthYear: number
    birthMonth: number
    yearGroup: number
  }
  onChange: (payload: {
    username?: string
    nickname?: string
    birthYear?: number
    birthMonth?: number
    yearGroup?: number
  }) => void
}

const ChildCard = (props: IChildCardProps) => {
  const { displayModal, dismissModal } = ModalState.useContainer()

  const HELPS = {
    username: 'Your child will use this username to log in.',
    nickname: 'Name / Nickname will only be visible to you.',
    difficulty:
      "You can adjust level of difficult in the child's settings area in your dashboard at any time.",
  }

  const displayHelp = (help: string) => {
    const HELP = HELPS[help]
    if (!HELP) return
    displayModal(<HelpModal dismiss={dismissModal}>{HELP}</HelpModal>)
  }

  return (
    <Wrapper>
      <Field>
        <div className="field--title">
          Username
          <HelpButton onClick={() => displayHelp('username')}>
            <Button height={22} theme="yellow" round>
              <Help />
            </Button>
          </HelpButton>
        </div>
        <div className="selected">{props.child.username}</div>
      </Field>
      <Field>
        <div className="field--title">
          Name / Nickname
          <HelpButton onClick={() => displayHelp('nickname')}>
            <Button height={22} theme="yellow" round>
              <Help />
            </Button>
          </HelpButton>
        </div>
        <Input
          placeholder="E.g. Will R"
          value={props.child.nickname || ''}
          onChange={(e) => props.onChange({ nickname: e.target.value })}
        />
      </Field>
    </Wrapper>
  )
}

export default ChildCard
