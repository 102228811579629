import Loader from 'components/UI/Loader'
import { Button, useAsync } from 'nzk-react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { StepContent, StepWrapper } from '.'
import Box from '../components/Box'
import { useOnboardingPageState } from '../index.state'

const Content = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title-2 {
    color: #F15A24;
    text-align: center;
    font-family: 'Rammetto One';
    margin-bottom: 25px;
  }
  .info {
    line-height: 1.4;
  }
  img {
    width: 100%;
    margin-bottom: 20px;
  }
`

const Code = styled.div`
  background-color: #00FF00;
  color: #006837;
  display: flex;
  justify-content: center;
  font-family: 'Rammetto One';
  padding: 3px 30px;
  border-radius: 100px;
  margin-top: 10px;
  margin-bottom: 6px;
`

export default () => {
  const [copied, setCopied] = useState(false)
  const { generateTrialShopCoupon, stepsHook } = useOnboardingPageState()
  const { nextStep } = stepsHook

  const { data, loading } = useAsync({
    asyncFunc: () => {
      return generateTrialShopCoupon()
    },
    immediate: true
  })

  const onBuyAction = () => {
    if (typeof window === 'undefined') return
    window.open('https://www.nightzookeeper.com/shop/products/complete-book-series')
  }

  const onCopyAction = () => {
    try {
      navigator.clipboard.writeText(data?.code || '')
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    } catch (err) {
      // Failed to copy to clipboard
    }
  }

  return <StepWrapper>
    <StepContent>
      <Box>
        <Content>
          <div className='title'>A Welcome Gift</div>
          <div className='info'>Enjoy the Night Zookeeper book series with this exclusive welcome discount code.</div>
          <div className='title-2'>30% Off Discount Code</div>
          <img src='/images/banner-book-bundle.png' alt='Books' />
          <div className='info'>30% off one or more of our books with this discount code:</div>
          <Button size='x-small' theme='confirm' onClick={onCopyAction}>Copy code</Button>
          {
            loading
              ? <Loader size={30} color='#F15A24' />
              : <Code>{copied ? 'Copied!' : (data?.code || '')}</Code>
          }
          <div className='info primary'>Voucher expires in <b>7 days.</b></div>
          <Button size='regular' theme='primary' onClick={onBuyAction}>Buy Books</Button>
        </Content>
        <div className='action'>
          <Button size='small' theme='primary' onClick={nextStep}>Next</Button>
        </div>
      </Box>
    </StepContent>
  </StepWrapper>
}