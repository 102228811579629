import Loader from 'components/UI/Loader'
import { navigate } from 'gatsby'
import { Button } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'
import { StepContent, StepWrapper } from '.'
import Box from '../components/Box'
import { useOnboardingPageState } from '../index.state'

const LoaderWrapper = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default () => {
  const { loadingData, loadingCanCreatePassword } =  useOnboardingPageState()

  const onSubmit = async () => {
    navigate('/')
  }

  if (loadingData || loadingCanCreatePassword) return <LoaderWrapper>
    <Loader color='#fff' />
  </LoaderWrapper>

  return <StepWrapper>
    <StepContent>
      <Box>
      <div className='title'>Wrong link</div>
      <div className='info'>It looks like your link has expired or is invalid</div>
      <div className='info'>If the problem persists please contact <a href="mailto:support@nightzookeeper.com">support@nightzookeeper.com</a></div>
        <div className='action'>
          <Button size='regular' theme='primary' onClick={onSubmit}>Next</Button>
        </div>
      </Box>
    </StepContent>
  </StepWrapper>
}