import React, { useEffect, useState } from "react";
import styled from "styled-components";

const CheckBoxWrapper = styled.div`
  position: relative;
  width: 42px;
`

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px;
  border-radius: 15px;
  background: #1A0425;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    margin-top: 1px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`

const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  &:checked + ${CheckBoxLabel} {
    background: ${props => props.disabled ? '#1A0425' : '#8CC63F'};
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 23px;
      height: 23px;
      margin-left: 18px;
      margin-top: 1px;
      transition: 0.2s;
    }
  }
`;

interface ToggleProps {
  selected: boolean
  onChange: Function
  disabled?: boolean
  // size: string
}

const Toggle = (props: ToggleProps) => {
  const [selected, setSelected] = useState(props.selected)

  const handleChange = () => {
    setSelected(!selected)
    props.onChange(!selected)
  }

  useEffect(() => {
    setSelected(props.selected)
    // eslint-disable-next-line
  }, [props])

  return (
    <CheckBoxWrapper onClick={() => !props.disabled && handleChange()}>
        <CheckBox checked={selected} type="checkbox" disabled={props.disabled} />
        <CheckBoxLabel htmlFor="checkbox" />
    </CheckBoxWrapper>
  );
}

Toggle.defaultProps = {
  disabled: false
}

export default Toggle
