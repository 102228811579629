import { IconButton, Whatsapp } from 'nzk-react-components'
import PropTypes from 'prop-types'
import React from 'react'

const WhatsappShareButton = ({ text, link }) => {
  return <a style={{ textDecoration: 'none' }} alt='Whatsapp' href={`whatsapp://send?text=${text} ${link}`} data-action='share/whatsapp/share' data-href={link}>
    <IconButton icon={<Whatsapp />} backgroundColor='#25D366' size='x-small'>Share to Whatsapp</IconButton>
  </a>
}

WhatsappShareButton.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string
}

export default WhatsappShareButton
