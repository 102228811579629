import { useLocation } from '@reach/router'
import { useAsync } from 'nzk-react-components'
import { useEffect, useMemo } from 'react'
import { useCurrentUserState } from 'state/CurrentUserState'
import { createContainer } from 'unstated-next'
import useSteps from './hooks/useSteps'
import useData from './useData'

const OnboardingPageState = () => {
  const stepsHook = useSteps()
  const location = useLocation()
  const { getData, canCreatePassword, createPassword, updateUser, inviteFriends, generateTrialShopCoupon } = useData()
  const urlParams = new URLSearchParams(location.search)
  const { currentUser, login } = useCurrentUserState()

  const { data, loading: loadingData, execute: fetchData } = useAsync({
    asyncFunc: async () => {
      return getData()
    },
    immediate: false,
  })

  const { data: canCreatePasswordData, loading: loadingCanCreatePassword, execute: fetchCanCreatePassword } = useAsync({
    asyncFunc: async () => {
      if (urlParams.get("userId") && urlParams.get("hash")) {
        return canCreatePassword(urlParams.get("userId")!, urlParams.get("hash")!)
      }
      return null
    },
    immediate: false,
  })

  const isLoggedIn = useMemo(() => {
    return currentUser?._id && true
  }, [currentUser])

  const canTakeOnboarding = useMemo(() => {
    if (isLoggedIn
      || (urlParams.get("userId") && urlParams.get("hash") && canCreatePasswordData?._id)) {
      return true
    }
    return false
  }, [canCreatePasswordData, isLoggedIn])

  useEffect(() => {
    if (isLoggedIn) {
      fetchData()
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (!isLoggedIn) {
      fetchCanCreatePassword()
      stepsHook.goToStep('PASSWORD')
    }
  }, [isLoggedIn, stepsHook.step])

  useEffect(() => {
    if (data?.hasPassword && stepsHook.step === 'PASSWORD') {
      stepsHook.goToStepIndex(1)
    }
  }, [data, stepsHook.step])

  const onCreatePassword = async (password) => {
    const userId = urlParams.get("userId")
    const hash = urlParams.get('hash')
    if (!userId || !hash) return null
    
    const { _id, sharedSecretKey } = await createPassword(userId, hash, password)
    await login(_id, sharedSecretKey, true)
    await fetchData()
    return true
  }

  return {
    stepsHook,
    data,
    canCreatePasswordData,
    onCreatePassword,
    canTakeOnboarding,
    loadingData,
    loadingCanCreatePassword,
    updateUser: (user) => updateUser(user),
    inviteFriends: (emails) => inviteFriends(emails),
    generateTrialShopCoupon
  }
}

const OnboardingPageStateContainer = createContainer(OnboardingPageState)

export const useOnboardingPageState = OnboardingPageStateContainer.useContainer
export const OnboardingPageProvider = OnboardingPageStateContainer.Provider
