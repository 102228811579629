import { Facebook, IconButton } from 'nzk-react-components';
import React from 'react';

interface IProps {
  link: string
}

const FacebookShareButton = ({ link }: IProps) => {

  const onClick = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${link}`, "pop", "width=600, height=400, scrollbars=no");
  }

  return <IconButton icon={<Facebook />} onClick={onClick} backgroundColor='#4267B2' size='x-small'>Share to Facebook</IconButton>
}

export default FacebookShareButton
