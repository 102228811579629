import Loader from 'components/UI/Loader'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import Nav from './components/Nav'
import { OnboardingPageProvider, useOnboardingPageState } from './index.state'
import Children from './steps/Children'
import Password from './steps/Password'
import Privacy from './steps/Privacy'
import Referral from './steps/Referral'
import WelcomeGift from './steps/WelcomeGift'
import WrongLink from './steps/WrongLink'
import bgImg from './assets/creatablespurple.jpg'

const Wrapper = styled.div`
  padding: 0;
  margin: 0;
  margin-bottom: 100px;
  margin-top: 30px;
`

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('${bgImg}');
  background-position: center;
  background-size: cover;
  z-index: -1;
`

const Content = styled.div``

const LoaderWrapper = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Onboarding = () => {
  const {
    stepsHook,
    loadingData,
    canTakeOnboarding,
    loadingCanCreatePassword,
  } = useOnboardingPageState()
  const { step } = stepsHook

  const content = useMemo(() => {
    if (!canTakeOnboarding) return <WrongLink />
    if (step === 'PASSWORD') return <Password />
    if (step === 'CHILDREN') return <Children />
    if (step === 'PRIVACY') return <Privacy />
    if (step === 'REFERRAL') return <Referral />
    if (step === 'WELCOME-GIFT') return <WelcomeGift />
    return null
  }, [step, canTakeOnboarding])

  return (
    <Wrapper>
      <Background />
      <Nav />
      {loadingData || loadingCanCreatePassword ? (
        <LoaderWrapper>
          <Loader color="#fff" />
        </LoaderWrapper>
      ) : (
        <Content>{content}</Content>
      )}
    </Wrapper>
  )
}

export default (props) => {
  return (
    <OnboardingPageProvider initialState={props}>
      <Onboarding />
    </OnboardingPageProvider>
  )
}
