import styled from 'styled-components'

export const StepWrapper = styled.div`
  color: #45106b;
  margin-top: 20px;
  max-width: 400px;
  margin: 20px auto 0 auto;
  padding: 0 20px;
`

export const StepTitle = styled.div`
  font-size: 22px;
  line-height: 26px;
  text-shadow: 0px 3px #afafaf, 0px 6px rgba(0, 0, 0, 0.3);
  color: #45106b;
  font-family: 'Rammetto One', cursive;
  margin-bottom: 0px;
  text-align: center;
  margin-bottom: 30px;
`

export const StepDescription = styled.div``

export const StepContent = styled.div``

export const StepActions = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
`

