import { StudentPageState } from 'components/pages/StudentPage/index.state'
import BatchPermissionsModal from 'components/pages/StudentPage/StudentPageFeatures/components/BatchPermissionsModal'
import { IHookReturn } from 'components/pages/StudentPage/StudentPageFeatures/components/Selector'
import SelectorGroup from 'components/pages/StudentPage/StudentPageFeatures/components/SelectorGroup'
import useCommentingControl from 'components/pages/StudentPage/StudentPageFeatures/hooks/useCommentingControl'
import useFriendsControl from 'components/pages/StudentPage/StudentPageFeatures/hooks/useFriendsControl'
import useParentLocksControl from 'components/pages/StudentPage/StudentPageFeatures/hooks/useParentLocksControl'
import useSharingControl from 'components/pages/StudentPage/StudentPageFeatures/hooks/useSharingControl'
import Loader from 'components/UI/Loader'
import { Button, Comment, Heart, Nzk, NzkPurple, Projects, Trophy } from 'nzk-react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { StepContent as BaseStepContent, StepWrapper } from '.'
import Box from '../components/Box'
import { useOnboardingPageState } from '../index.state'
import { getAge } from './Children'

const StepContent = styled(BaseStepContent)`
  display: flex;
  flex-direction: column;
  align-items: center;

`

const Child = styled.div`
  min-width: 280px;
  > .info {
    margin-bottom: 0;
  }
  > :nth-child(2) {
    margin-top: 0;
  }
  > .action {
    display: flex;
    justify-content: center;
  }
  
  .privacy {
    position: relative;
    display: flex;
    justify-content: center;
    > :first-child {
      margin-top: -12px;
    }
    .hide-button {
      position: absolute;
      top: 14px;
    }
    .copy-settings {
      display: flex;
      justify-content: center;
      margin-top: 14px;
    }
  }
  margin-bottom: 30px;
`

const LoaderWrapper = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ChildPrivacy = () => {
  const { loading } = StudentPageState.useContainer()
  if (loading) return <LoaderWrapper><Loader color='#fff' /></LoaderWrapper>

  return <SelectorGroup
    primary='#F15A24'
    editable
    selectAllDisabled
    selectors={[
      {
        icon: (
          <Button height={60} backgroundColor="#6F1EA9" round>
            <Heart />
          </Button>
        ),
        title: 'Friends',
        description: 'Follow other users and have followers',
        helpText: `If enabled, children can add and remove friends they are following and others can follow them. You can add/remove individual friends from the 'Friends' menu in the dashboard too.
      Children are notified when a friend shares something, making it easier to keep up to date with what they post. However, no conversations are possible in real time, all content is moderated and approved first.`,
        hook: (): IHookReturn => useFriendsControl(),
      },
      {
        icon: (
          <Button height={60} backgroundColor="#009EE2" round>
            <Nzk />
          </Button>
        ),
        title: 'Sharing',
        description:
          'User can share work for other Night Zookeeper to see.',
        helpText: `If disabled, all the child's work will only be visible to you, them and our tutors. If enabled, their profile, and tutor approved, shared work will be visible to other logged in Night Zookeepers. Your child can choose whether a piece of writing is 'private' or 'shared' each time they submit.`,
        hook: useSharingControl,
      },
      {
        icon: (
          <Button height={60} backgroundColor="#E94E1B" round>
            <Trophy />
          </Button>
        ),
        title: 'Leagues',
        description: 'Users see their position in leagues',
        helpText: `As children do activities on Night Zookeeper they get points which determine their position in our monthly league table. Disable this feature to remove links within the child's account to the league tables.`,
        hook: (): IHookReturn => useParentLocksControl('leagues'),
      },
      {
        icon: (
          <Button height={60} backgroundColor="#009EE2" round>
            <Comment />
          </Button>
        ),
        title: 'Commenting',
        description: 'Other users can comment on shared writing',
        helpText: `If this is enabled, other Night Zookeepers will be able to write comments on writing this child has shared. ALL COMMENTS ARE MODERATED. If disabled, the child will only receive comments from Night Zookeeper tutors or animals.`,
        hook: (): IHookReturn => useCommentingControl(),
      },
      {
        icon: (
          <Button height={60} backgroundColor="#6F1EA9" round>
            <NzkPurple />
          </Button>
        ),
        title: 'Blog',
        description: 'Showcase of writing from our community',
        helpText: `Night Zookeeper chooses some pieces of work to showcase in each blog. A child can only see the blog for their 'team' (each child is automatically assigned randomly to one of our teams when they join).`,
        hook: (): IHookReturn => useParentLocksControl('blog'),
      },
      {
        icon: (
          <Button height={60} backgroundColor="#579800" round>
            <Projects />
          </Button>
        ),
        title: 'Projects',
        description: 'Projects from our team or friends',
        helpText: `Projects are only available by default to children aged 8 and over. In projects, children can share ideas, drawings or writing on the project's topic. They can also create new projects on certain days. Everything is moderated.`,
        hook: (): IHookReturn => useParentLocksControl('projects'),
      },
    ]}
  />
}

export default () => {
  const { data, stepsHook } = useOnboardingPageState()
  const { nextStep } = stepsHook
  const [copyToOthers, setCopyToOthers] = useState(false)
  const [shownUser, setShownUser] = useState<{ _id: string, username: string } | null>(data.children[0])

  const onSubmit = () => {
    nextStep()
  }

  return (
    <StepWrapper>
      <StepContent>
        <Box>
          <div className='title'>
            Set Privacy
          </div>
          <div className='info'>
            To comply with the <b>Children's Code</b>, we've disabled all community features. You can enable them here, or later in your dashboard.
          </div>
        {
          data.children.map((child, i) => <Child key={child._id}>
            <div className='title' style={{ fontSize: '25px' }}>
              Child {i + 1}
            </div>
            <div className='info primary' style={{ fontSize: '18px' }}>
              {child.nickname && `${child.nickname} - `} Age {getAge(child)} years old
            </div>
            
            { (!shownUser || shownUser?._id !== child._id) && <div className='action'>
              <Button backgroundColor='#F15A24' size='small' onClick={() => setShownUser(child)}>View</Button>
            </div> }
            <div className='privacy'>
              { shownUser?._id === child._id && <StudentPageState.Provider initialState={{ username: child.username, noPage: true }}>
                <div>
                  { copyToOthers && <BatchPermissionsModal dismiss={() => setCopyToOthers(false)} primary='#F15A24' /> }
                  <ChildPrivacy />
                  <div className='copy-settings'>
                    <Button size='small' theme='primary' onClick={() => setCopyToOthers(true)}>Copy to other children</Button>
                  </div>
                </div>
              </StudentPageState.Provider> }
              { shownUser?._id === child._id && <div className='hide-button'>
                <Button backgroundColor='#F15A24' size='small' onClick={() => setShownUser(null)}>Hide</Button>
              </div> }
            </div>
          </Child>)
        }
          <div className='action'>
            <Button size='regular' onClick={onSubmit} theme='primary'>Next</Button>
          </div>
        </Box>
      </StepContent>
    </StepWrapper>
  )
}
