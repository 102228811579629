import { Button } from 'nzk-react-components'
import React, { useState } from 'react'
import styled from 'styled-components'
import { StepContent, StepWrapper } from '.'
import Box from '../components/Box'
import ChildCard from '../components/ChildCard'
import { useOnboardingPageState } from '../index.state'

const ChildrenCards = styled.div`
  > * { margin-bottom: 20px; }
`

const Child = styled.div`
  > .age {
    font-size: 12px;
    margin-bottom: 8px;
  }
  > .info {
    margin-bottom: 0;
  }
`

const YEAR_TO_AGE = [
  { value: 0, label: '4-5' },
  { value: 1, label: '5-6' },
  { value: 2, label: '6-7' },
  { value: 3, label: '7-8' },
  { value: 4, label: '8-9' },
  { value: 5, label: '9-10' },
  { value: 6, label: '10-11' },
  { value: 7, label: '12+' },
]


export const getAge = (child) => {
  const now = new Date()
  const yearNow = now.getFullYear()
  const monthNow = now.getMonth()

  if (child.birthYear > 2000 && child.birthMonth) {
    let yearAge = yearNow - child.birthYear
    if (monthNow <= child.birthMonth) {
      yearAge -= 1
    }
    return yearAge
  }
  if (child.age) {
    return child.age
  }
  if (YEAR_TO_AGE[child.yearGroup]) {
    return `${YEAR_TO_AGE[child.yearGroup].label}`
  }
  return 'Not Set'
}

export default () => {
  const [loading, setLoading] = useState(false)
  const { data, stepsHook, updateUser } = useOnboardingPageState()
  const { nextStep } = stepsHook
  const [newChildren, setNewChildren] = useState(data.children)

  const onChildChange = (_id, payload) => {
    setNewChildren(children => {
      return children.map(c => c._id !== _id ? c : {
        ...c,
        ...payload
      })
    })
  }

  const onSubmit = async () => {
    if (loading) return
    setLoading(true)
    await Promise.all(newChildren.map(async child => {
      updateUser({
        _id: child._id,
        birthMonth: child.birthMonth,
        birthYear: child.birthYear,
        nickname: child.nickname,
        yearGroup: child.yearGroup,
      })
    }))
    setLoading(false)
    nextStep()
  }

  return <StepWrapper>
    <StepContent>
      <Box>
        <div className='title'>Child Details</div>
        <ChildrenCards>
          {newChildren.map((child, i) => <Child key={child._id}>
            <div className='title' style={{ fontSize: '25px', marginTop: '20px' }}>{ child.nickname || `Child ${i + 1}` }</div>
            <div className='info primary' style={{ fontSize: '18px' }}>Age {getAge(child)} yrs old</div>
            <ChildCard child={child} onChange={(payload) => onChildChange(child._id, payload)}/>
          </Child>)}
        </ChildrenCards>
        <div className='action'>
          <Button theme='primary' size='regular' onClick={onSubmit} disabled={loading}>{
            loading ? 'Loading...' : 'Next'
          }</Button>
        </div>
      </Box>
    </StepContent>
  </StepWrapper>
}