import styled from 'styled-components'

export default styled.div`
  position: relative;
  padding: 20px 25px 45px 25px;
  background-color: #fff;
  border: 2px solid #45106b;
  box-shadow: 0 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  color: #000;
  .title {
    text-align: center;
    font-size: 29px;
    margin: 12px 0;
    font-weight: bold;
    color: #45106b;
  }

  .title-2 {
    text-align: center;
    font-size: 20px;
    margin: 12px 0;
    font-weight: bold;
    color: #45106b;
  }

  .info {
    text-align: center;
    font-size: 13px;
    margin-bottom: 18px;
  }

  .primary {
    color: #45106b;
  }

  > .action {
    position: absolute;
    bottom: -30px;
    left: 0;
    display: flex;
    width: 100%;
    justify-content: center;
  }
`

