import { Button, Close } from 'nzk-react-components'
import React from 'react'
import styled from 'styled-components'

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0,0,0,0.6) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 21;
  padding: 15px;
`

const Wrapper = styled.div`
  position: relative;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background-color: #F15A24 !important;
  box-shadow: 0 4px 0 #913616,0 8px 0 rgba(0,0,0,0.4); 
  border-radius: 20px;
  color: #fff;
  padding: 30px 30px;
`

const CloseButton = styled.div`
  position: absolute;
  right: -11px;
  top: -11px;
  z-index: 2;
`

interface IProps {
  dismiss: () => void,
  children: any
}

const HelpModal = (props: IProps) => {
  return <Overlay>
    <Wrapper>
      <CloseButton onClick={props.dismiss}>
        <Button round theme='red' size='small'>
          <Close />
        </Button>
      </CloseButton>
      {props.children}
    </Wrapper>
  </Overlay>
}

export default HelpModal
