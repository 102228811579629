import FacebookShareButton from 'components/ShareButton/Facebook'
import WhatsappShareButton from 'components/ShareButton/Whatsapp'
import { Button, IconButton, Link as LinkIcon, Message } from 'nzk-react-components'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { StepContent, StepWrapper } from '.'
import Box from '../components/Box'
import Input from '../components/Input'
import { useOnboardingPageState } from '../index.state'

const Image = styled.img`
  width: 100%;
  border-radius: 8px;
`

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  > * { margin-bottom: 12px; }
`

const Invites = styled.div`
  margin-bottom: 25px;
  > * { margin-bottom: 6px; }
`

const Invite = styled.div`
  display: flex;
  align-items: center;
  > :first-child {
    margin-right: 12px;
  }
`

const ErrorMessage = styled.div`
  font-size: 12px;
  color: #F00;
  font-weight: bold;
`

export default () => {
  const [loading, setLoading] = useState(false)
  const [copied, setCopied] = useState(false)
  const emailRef = useRef<HTMLInputElement | null>(null)
  const { stepsHook } = useOnboardingPageState()
  const [incorrectEmail, setIncorrectEmail] = useState(false)
  const { nextStep } = stepsHook

  const [emails, setEmails] = useState<string[]>([])

  const { data, inviteFriends } = useOnboardingPageState()
  const referralLink = `https://nightzookeeper.com/parents?referralCode=${data.referralCode}`

  const onAddEmail = () => {
    if (!emailRef.current) return

    const validEmail = String(emailRef.current.value)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    if (!validEmail) {
      setIncorrectEmail(true)
      setTimeout(() => {
        setIncorrectEmail(false)
      }, 3000)
      return
    }

    const email = emailRef.current.value
    setEmails(d => [...d, email])
    emailRef.current.value = ''
  }

  const copyLink = () => {
    try {
      navigator.clipboard.writeText(referralLink)
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    } catch (err) {
      // Failed to copy to clipboard
    }
  }

  const url = '/images/free-resource.png'
  
  const formatAmount = (amount) => {
    let currency = 'USD'
    if (data.countryCode === 'GB') {
      currency = 'GBP'
    }
    return new Intl.NumberFormat('us-US', {
      style: 'currency',
      currency,
      maximumSignificantDigits: 3
    }).format(amount || 0)
  }

  const onSubmit = async () => {
    if (loading) return
    if (emails.length > 0) {
      setLoading(true)
      await inviteFriends(emails)
      setLoading(false)
    }
    nextStep()
  }

  return <StepWrapper>
    <StepContent>
      <Box>
      <div className='title'>Invite friends</div>
      <div className='info'>Share that you are trialing Night Zookeeper by submitting 2 or more friends' email addresses, and you'll get a free resource!</div>
      <Image src={url} />
      <div className='info'>They'll be invited to join Night Zookeeper,  and when they start their paid subscription, it will count as a successful referral.</div>
      <Invites>
        {
          emails.map(e => <Input key={e} inverted value={e} readOnly />)
        }
        { incorrectEmail && <ErrorMessage>Incorrect email address</ErrorMessage> }
        <Invite>
          <Input ref={emailRef} placeholder='Email address' onKeyPress={e => e.which === 13 && onAddEmail()}/>
          <Button theme='primary' size='x-small' round onClick={onAddEmail}>
            <Message />
          </Button>
        </Invite>
      </Invites>
        <div className='title'>Refer a  friend</div>
        <div className='info'>Earn up to {formatAmount(50)} referring friends to Night Zookeeper!</div>
        <p className='info'>Receive {formatAmount(5)} off your next payment for every successful referral. Your friend will receive {formatAmount(5)} off too!</p>
        <p className='info'>Simply share the link below with your friends, family or parent groups.</p>
        <Input value={!copied ? referralLink : 'Copied!'} inverted={copied} onClick={copyLink} readOnly />
        <Links>
          <IconButton icon={<LinkIcon />} onClick={copyLink} backgroundColor='#009EE2' size='x-small'>Copy link</IconButton>
          <FacebookShareButton link={`${referralLink}`} />
          <WhatsappShareButton
            text={`Have you heard of Night Zookeeper? Night Zookeeper makes learning to write easy and fun for ages 6-12. Earn us both ${formatAmount(5)} credit by signing up with my link`}
            link={`${referralLink}`}
          />
        </Links>
        <div className='action'>
          <Button size='regular' theme='primary' onClick={onSubmit} disabled={loading}>{loading ? 'Loading...' : 'Next'}</Button>
        </div>
      </Box>
    </StepContent>
  </StepWrapper>
}