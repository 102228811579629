import { Button } from 'nzk-react-components'
import React, { useMemo, useRef, useState } from 'react'
import { useCurrentUserState } from 'state/CurrentUserState'
import styled from 'styled-components'
import { StepContent, StepWrapper } from '.'
import Box from '../components/Box'
import Form from '../components/Form'
import FormField from '../components/FormField'
import Input from '../components/Input'
import { useOnboardingPageState } from '../index.state'

const Errors = styled.ul`
  color: #f00;
  font-size: 14px;
`

const usePasswordsValidation = (password, confirm) => {
  const [shouldDisplayErrors, setShouldDisplayErrors] = useState(false)

  const validatePassword = (password) => {
    const errors: string[] = []
    if (password.length < 6)
      errors.push('Password needs to be at least 6 characters long')
    return errors
  }

  const validateConfirm = (password, confirm) => {
    const errors: string[] = []
    if (password !== confirm)
      errors.push('Passwords don\'t match.')
    return errors
  }

  const errors = useMemo(() => {
    const passwordErrors = validatePassword(password)
    const passwordsError = validateConfirm(password, confirm)
    return [...passwordErrors, ...passwordsError]
  }, [password, confirm])

  const validate = () => {
    setShouldDisplayErrors(true)
    return errors.length === 0
  }

  const displayedErrors = useMemo(() => {
    if (shouldDisplayErrors) return errors
    return []
  }, [errors, shouldDisplayErrors])

  return {
    errors: displayedErrors,
    validate,
    canSubmit: errors.length === 0
  }
}

export default () => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const { currentUser } = useCurrentUserState()
  const { onCreatePassword, stepsHook, canCreatePasswordData } = useOnboardingPageState()
  const { nextStep } = stepsHook
  const { errors, validate, canSubmit } = usePasswordsValidation(password, confirmPassword)
  const [loading, setLoading] = useState(false)
  const passwordRef = useRef<HTMLInputElement | null>(null)
  const confirmPasswordRef = useRef<HTMLInputElement | null>(null)

  const onSubmit = async () => {
    if (loading) return
    const isValid = validate()
    if (!isValid) return
    setLoading(true)
    try {
      await onCreatePassword(password)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
    nextStep()
    return false
  }

  return <StepWrapper>
    <StepContent>
      <Form>
        <Box>
          <div className='title'>Welcome to your trial</div>
          <div className='info'>You've been sent an email to <b>{(canCreatePasswordData || currentUser)?.email}</b> with your login details.</div>
          <div className='title-2'>Access your dashboard</div>
          <div className='info'>First, let's create a password for your dashboard.</div>
            { errors.length > 0 && <Errors>
              { errors.map((error, i) => <li key={i}>{error}</li>)}
            </Errors> }
            <FormField>
              <Input
                name='password'
                ref={passwordRef}
                placeholder='Create a password'
                autoComplete='new-password'
                type='password'
                value={password}
                onKeyPress={(e) => e.which === 13 && confirmPasswordRef.current?.focus()}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormField>
            <FormField>
              <Input
                name='password-confirm'
                ref={confirmPasswordRef}
                placeholder='Confirm password'
                type='password'
                autoComplete='new-password'
                value={confirmPassword}
                onKeyPress={(e) => e.key === 'Enter' && canSubmit && onSubmit()}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormField>
          <div className='action'>
            <Button theme='primary' size='regular' onClick={onSubmit} disabled={!canSubmit}>{ loading ? 'Loading...' : 'Next' }</Button>
          </div>
        </Box>
      </Form>
    </StepContent>
  </StepWrapper>
}