import { useApolloClient } from '@apollo/client'
import CAN_CREATE_PASSWORD from './graphql/canCreatePassword.graphql'
import CREATE_PASSWORD from './graphql/createPassword.graphql'
import GENERATE_TRIAL_SHOP_COUPON from './graphql/generateTrialShopCoupon.graphql'
import GET_DATA from './graphql/getData.graphql'
import INVITE_FRIENDS from './graphql/inviteFriends.graphql'
import UPDATE_USER from './graphql/updateUser.graphql'

const useData = () => {
  const client = useApolloClient()

  const canCreatePassword = async (_id: string, hash: string): Promise<{
    _id: string
    name: string
    email: string
  }> => {
    const { data } = await client.query({
      query: CAN_CREATE_PASSWORD,
      variables: {
        _id,
        hash
      },
      fetchPolicy: 'network-only'
    })
    return data.canCreatePassword
  }

  const createPassword = async (userId, hash, password): Promise<{ _id: string, sharedSecretKey: string }> => {
    const { data } = await client.mutate({
      mutation: CREATE_PASSWORD,
      variables: {
        _id: userId,
        hash,
        password
      }
    })
    return data.createPassword
  }

  const updateUser = async (user): Promise<{ _id: string }> => {
    const { data } = await client.mutate({
      mutation: UPDATE_USER,
      variables: {
        user
      }
    })
    return data.updateUser
  }

  const inviteFriends = async (emails): Promise<boolean> => {
    const { data } = await client.mutate({
      mutation: INVITE_FRIENDS,
      variables: {
        emails
      }
    })
    return data.inviteFriends
  }

  const generateTrialShopCoupon = async (): Promise<{ createdAt: number, code: string }> => {
    const { data } = await client.mutate({
      mutation: GENERATE_TRIAL_SHOP_COUPON
    })
    return data.generateTrialShopCoupon?.trialShopCoupon
  }

  const getData = async (): Promise<{
    _id: string
    hasPassword: boolean
    referralCode: string
    children: {
      _id: string
      username: string
      countryCode: string
      yearGroup: number
      birthMonth: number
      birthYear: number
    }[]
  }> => {
    const { data } = await client.query({
      query: GET_DATA,
      fetchPolicy: 'network-only'
    })
    return data.me
  }

  return { canCreatePassword, createPassword, getData, updateUser, inviteFriends, generateTrialShopCoupon }
}

export default useData
