import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { useEffect, useMemo, useState } from 'react'
import { useCurrentUserState } from 'state/CurrentUserState'

type Steps = 'PASSWORD' | 'CHILDREN' | 'PRIVACY' | 'REFERRAL' | 'WELCOME-GIFT'

const STEPS: Steps[] = ['PASSWORD', 'CHILDREN', 'PRIVACY', 'REFERRAL', 'WELCOME-GIFT']

const useSteps = () => {
  const location = useLocation()
  const { currentUser } = useCurrentUserState()
  const [step, setStep] = useState<Steps>('PASSWORD')

  const isLoggedIn = useMemo(() => {
    return currentUser?._id && true
  }, [currentUser])

  const filteredSteps = useMemo(() => {
    return STEPS.filter(step => {
      // Disable those steps for non UK
      if (['PRIVACY'].indexOf(step) >= 0 && currentUser?.countryCode !== 'GB') {
        return false
      }
      if (['PRIVACY', 'CHILDREN'].indexOf(step) >= 0 && currentUser?.userType === 'teacher') {
        return false
      }
      return true
    })
  }, [currentUser])

  useEffect(() => {
    const step = new URLSearchParams(location.search).get('step')
    if (step && filteredSteps.indexOf(step as Steps) >= 0) {
      setStep(step as Steps)
    }
  }, [])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(location.search)
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
      params.set('step', step)
      window.history.replaceState(
        null,
        '',
        `${location.pathname}?${params.toString()}`
      )
    }
    
  }, [step])

  const getStepIndex = (step: Steps) => {
    return filteredSteps.indexOf(step)
  }

  const stepIndex = useMemo(() => {
    return getStepIndex(step)
  }, [step])

  const goToStepIndex = (index) => {
    if (!isLoggedIn && index > 0) {
      setStep('PASSWORD')
      return
    }
    if (index >= 0 && index < filteredSteps.length) {
      setStep(filteredSteps[index])
    }
  }

  const nextStep = () => {
    const nextIndex = stepIndex + 1
    if (nextIndex > filteredSteps.length - 1) {
      navigate('/')
      return
    }
    goToStepIndex(nextIndex)
  }

  const goToStep = (step: Steps) => {
    goToStepIndex(getStepIndex(step))
  }

  const previousStep = () => {
    const nextIndex = stepIndex - 1
    if (nextIndex < 0) return
    goToStepIndex(nextIndex)
  }

  return {
    STEPS: filteredSteps,
    step,
    stepIndex,
    nextStep,
    previousStep,
    goToStepIndex,
    goToStep
  }
}

export default useSteps
