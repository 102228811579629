import React from "react"
import Onboarding from '../components/pages/Onboarding'
import BaseLayout from '../layouts/base'

const OnboardingPage = props => {
  if (typeof window === "undefined") return null
  return (
    <BaseLayout>
      <Onboarding {...props} />
    </BaseLayout>
  )
}

export default OnboardingPage
